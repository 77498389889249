









import {
  defineComponent,
  ref,
  useContext
} from '@nuxtjs/composition-api';

import {
  SfBreadcrumbs,
  ContactUsForm,
} from '~/components';

export default defineComponent({
  name: 'ContactUsPage',
  components: {
    SfBreadcrumbs,
    ContactUsForm,
  },
  setup() {
    const {
      app: { i18n },
      localePath
    } = useContext();

    const breadcrumbs = ref([]);
    breadcrumbs.value = [
      {
        link: localePath('/'),
        text: i18n.t('Home') as string,
      },
      {
        link: '#',
        text: i18n.t('Contact Us')
      }
    ];

    return {
      breadcrumbs
    };
  }
});

